<template lang="pug">
div
  h1.ma-4.text-h2
    | 
  p.ma-4 {{$t('missing')}}
  p
    v-btn(text, @click="goBack()") 
      v-icon.mr-4 mdi-arrow-left-circle
      | Ga een stap terug
</template>

<script>
export default {
  mounted() {},
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>